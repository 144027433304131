import React, { ReactElement } from "react";
import WhyUsImg from "../icons/img2";
export default function WhyQuoto(): ReactElement {
    return (
        <section className="flex lg:flex-row flex-col lg:gap-32 gap-10 lg:my-24 my-12 ">
            <div className="">
                <h2 className="font-bold sm:text-3xl text-xl leading-7 sm:leading-[45px] ">
                    Why QuoteSpot.com
                </h2>
                <p className="font-light mt-4 lg:mt-7 lg:text-lg ">
                    At QuoteSpot.com, we understand the challenges that
                    businesses face in today's fast-paced market. That's why we
                    offer a platform that streamlines the process of getting
                    quotes for essential products and services. With just a few
                    clicks, you can compare quotes from a variety of providers
                    and choose the best one for your business. Not only does
                    this save you time, but it also helps you get the best deal.
                </p>
                <p className="font-light mt-4 lg:mt-7 lg:text-lg ">
                    Our team of experts works tirelessly to provide you with
                    accurate and up-to-date information, so you can make
                    informed decisions. Plus, our service is completely free, so
                    there's no risk or obligation involved. Choose Quoto.com for
                    all your business needs and enjoy the convenience of having
                    everything in one place.
                </p>
            </div>
            <div className="mx-auto lg:ml-0  lg:mt-5 ">
                <WhyUsImg />
            </div>
        </section>
    );
}
